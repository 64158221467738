import React from "react";
import { Navigation } from "../components/navigation";

export default function ExectiveMenu  ()  {
  return (<>
    <Navigation></Navigation>
    <div  style={{borderBottom:"1px solid #971A1A",marginTop:"10%"}}>
      <div className="container">
        <div className="row" >
        <div className="col-xs-12 col-md-6">
            <h1>Working On it</h1>
        </div>
          
          {/* <div className="col-xs-12 col-md-6">
            
            <img style={{borderBottom:"2px solid #971A1A",boxShadow:"7px 10px 5px #D3D3D3"}} src="img/about.jpg" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text" >
              <h2 style={{color:"#293788"}}>About Us</h2>
              <p style={{color:"black"}}>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3 style={{color:"#293788"}}>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </div>
    </>
  );
};
